import React, { useState, useEffect } from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';


const Shop: React.FC = () => {


  return (
    <Container maxWidth="lg"> {/* コンテナの横幅を'lg'に設定して、テーブルを横幅いっぱいに */}

      商品購入ページ
    </Container>
  );
};

export default Shop;
