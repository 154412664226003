import React, { useState } from 'react';
import { Box, Typography, Grid, Paper, List, ListItem, ListItemText, Divider, Select, MenuItem, SelectChangeEvent } from '@mui/material';

// 注文詳細ステータスのデータ
const orderDetails = [
  {
    date: '2024-07-01',
    productName: 'エクソソーム１',
    quantity: 10,
    pointsUsed: 100,
    shippingStatus: '発送済み',
  },
  {
    date: '2024-07-02',
    productName: 'エクソソーム２',
    quantity: 5,
    pointsUsed: 50,
    shippingStatus: '未発送',
  },
  {
    date: '2024-07-03',
    productName: 'エクソソーム１',
    quantity: 20,
    pointsUsed: 200,
    shippingStatus: '発送済み',
  },
  {
    date: '2024-07-04',
    productName: 'エクソソーム３',
    quantity: 15,
    pointsUsed: 150,
    shippingStatus: '未発送',
  },
  {
    date: '2024-07-04',
    productName: 'エクソソーム３',
    quantity: 15,
    pointsUsed: 150,
    shippingStatus: '未発送',
  },
  {
    date: '2024-07-04',
    productName: 'エクソソーム３',
    quantity: 15,
    pointsUsed: 150,
    shippingStatus: '未発送',
  },
  {
    date: '2024-07-04',
    productName: 'エクソソーム３',
    quantity: 15,
    pointsUsed: 150,
    shippingStatus: '未発送',
  },
  {
    date: '2024-07-04',
    productName: 'エクソソーム３',
    quantity: 15,
    pointsUsed: 150,
    shippingStatus: '未発送',
  },
  // 他の注文詳細データも追加可能
];

const notifications = [
  "・商品発送しました",
  "・次回メンテナンスのお知らせ: 2024-08-15",
  "・システムアップデートのお知らせ: 2024-09-01",
  // 追加のお知らせ項目
];

const Home: React.FC = () => {
  const [selectedMonth, setSelectedMonth] = useState<string>('2024-07');

  const handleMonthChange = (event: SelectChangeEvent<string>) => {
    setSelectedMonth(event.target.value);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: '550px', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" gutterBottom>
                注文詳細履歴
              </Typography>
              <Select
                value={selectedMonth}
                onChange={handleMonthChange}
                displayEmpty
                inputProps={{ 'aria-label': '月別選択' }}
              >
                <MenuItem value="2024-07">2024年7月</MenuItem>
                <MenuItem value="2024-06">2024年6月</MenuItem>
                <MenuItem value="2024-05">2024年5月</MenuItem>
                {/* 他の月も追加可能 */}
              </Select>
            </Box>
            <Box sx={{ flexGrow: 1, overflow: 'auto', border: '1px solid #ccc', borderRadius: '4px', p: 1 }}>
              <List>
                {orderDetails.slice().reverse().map((order, index) => (
                  <React.Fragment key={index}>
                    <ListItem>
                      <ListItemText
                        primary={`日付: ${order.date} - 商品名: ${order.productName}`}
                        secondary={`個数: ${order.quantity} - 消費ポイント: ${order.pointsUsed} - 発送ステータス: ${order.shippingStatus}`}
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: '550px', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ flexGrow: 1, overflow: 'auto', border: '1px solid #ccc', borderRadius: '4px', mb: 0 }}>
              <Typography variant="h6" gutterBottom sx={{ position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 1, p: 1 }}>
                機構からのお知らせ
              </Typography>
              <List sx={{ p: 0, m: 0 }}>
                {notifications.map((notification, index) => (
                  <ListItem key={index} sx={{ p: 0 }}>
                    <ListItemText primary={notification} primaryTypographyProps={{ variant: 'body2', sx: { lineHeight: '1.5' } }} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
