import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Toolbar } from '@mui/material';
import { Home, ShoppingCart } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const drawerWidth = 300; // サイドメニューの幅を300に設定

const Sidebar: React.FC = () => {
  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
    >
      <Toolbar />
      <Divider />
      <List>
        <ListItem button component={Link} to="/dashboard">
          <ListItemIcon><Home /></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <Divider />
        <Divider />
        <ListItem button component={Link} to="/dashboard/shop">
          <ListItemIcon><ShoppingCart /></ListItemIcon>
          <ListItemText primary="購入ページ" />
        </ListItem>


      </List>
    </Drawer>
  );
};

export default Sidebar;
