import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5', // 明るめの青
    },
    secondary: {
      main: '#f50057', // 明るめのピンク
    },
    background: {
      default: '#f0f0f0', // 全体の背景色を明るめに
      paper: '#ffffff', // コンポーネントの背景色を明るめに
    },
    text: {
      primary: '#000000', // メインのテキスト色を黒に
      secondary: '#424242', // サブのテキスト色を濃いグレーに
    },
  },
});

export default theme;
